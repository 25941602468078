import axios from "axios"

// if we call the application via our internal domain, we don´t use the Kong proxy
export const useKongProxy = !window.location.href.includes("td-wowi-shop-frontend");

const internalBaseURL = "api/";

const developmentBaseURL = new URL(
  internalBaseURL,
  process.env.REACT_APP_DEV_BACKEND_BASE_URL ||
  "https://td-wowi-shop-backend-integration.i22hosting.de"
).toString();

const kongProxyBaseURLMap = {
  production: "/mietershop/",
  staging: "/mietershop/",
  preview: "/mietershop-preview/",
  integration: "/mietershop-integration/",
};

export const kongProxyBaseURL = kongProxyBaseURLMap[process.env.REACT_APP_CUSTOM_ENV || process.env.NODE_ENV];

const baseURL = process.env.NODE_ENV === "development" ?
  developmentBaseURL :
  `${useKongProxy ? kongProxyBaseURL : "/"}${internalBaseURL}`;

export default class APIClient {
  constructor() {
    this.axios = null
    this.init()
  }

  init() {
    this.createAxios()
  }

  createAxios() {
    this.axios = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      responseType: "json",
    })
  }

  setToken(token) {
    this.axios.defaults.headers.common.AuthToken = token
  }

  async post(url, payload) {
    const res = await this.axios.post(url, payload)
    return res.data
  }

  async put(url, payload) {
    const res = await this.axios.put(url, payload)
    return res.data
  }

  async get(url, params) {
    const res = await this.axios.get(url, { params })
    return res.data
  }

  async delete(url, payload) {
    const res = await this.axios.delete(url, { data: payload })
    return res.data
  }
}
